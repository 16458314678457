import React, { Component } from 'react';
import './AdminPanel.css';
import UserEditPanel from './UserEditPanel';

export default class AdminUserEdit extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            query: '',
            filtered: null,
            userEdit: null,
        }
    }

    Search = (query) => {

        // this.setState({query: query});

        if (query === '') return this.setState({filtered: null});

            const users = this.props.data.users;

            const filtered = [];

            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                if (user.email.includes(query.toLowerCase())) filtered.push(user);
            }

            // return filtered;
            // this.setState({filtered: filtered});
            this.setState({filtered: filtered});
    };
    

    render() {

        return (
            <div className="AdminUserEdit">
                <h4 style={{margin: '2rem 0 1rem 0'}}>Edit a user</h4>

                <div className="field">
                    <p className="title">Email Address</p>
                    <div className="inputGroup">
                        <div style={{padding:'0.5rem'}}><i className="fas fa-search"></i></div>
                        <input onChange={(e) => this.Search(e.currentTarget.value)} type="text" placeholder="Search..." />
                    </div>
                </div>
                {!this.state.filtered ? '' : (
                    <div>
                        <h5 style={{marginBottom: '0.5rem'}}>{this.state.filtered.length} result{this.state.filtered.length === 1 ? '' : 's'}</h5>
                        <div className="grid c1fr1fr1fr" style={{padding: '4px'}}>
                            <p className="title">User</p>
                            <p className="title">Access Level</p>
                            <p className="title">Completion rate</p>
                        </div>
                        <div className="searchTable">
                            {!this.state.filtered ? '' : this.state.filtered.map(v => {

                                let vidsWatched = 0;
                                let pptsViewed = 0;

                                for (let i = 0; i < v.tracking.progress.length; i++) {
                                    const vid = v.tracking.progress[i];
                                    if (vid.finished) vidsWatched++;
                                }

                                return (
                                    <div key={'user_'+v.email} onClick={() => this.setState({userEdit: v})} className="user grid c1fr1fr1fr">
                                        <div className="searchUser">
                                            <p className="title" style={{margin:'4px 0'}}>{v.email}</p>
                                            <p>{v.firstName} {v.lastName}</p>
                                        </div>
                                        <div className="searchUser">
                                            {/* <p className="title" style={{margin:'4px 0'}}>Level</p> */}
                                            <p>{this.props.ranks[v.rank].split(" - ")[0]}</p>
                                        </div>
                                        <div className="searchUser">
                                            {/* <p className="title" style={{margin:'4px 0'}}>Completion rate</p> */}
                                            <p>Videos:  {vidsWatched}/{v.tracking.progress.length}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                {!this.state.userEdit ? '' : <UserEditPanel editor={this.props.user} ranks={this.props.ranks} onClose={() => {
                    this.setState({userEdit: null});
                    this.props.refresh();
                }} user={{
                    email: this.state.userEdit.email,
                    firstName: this.state.userEdit.firstName,
                    lastName: this.state.userEdit.lastName,
                    rank: this.state.userEdit.rank,
                    code: this.state.userEdit.passcode
                }} />}

            </div>
        )
    }
}
