import React, { Component } from 'react';
import './VideoPlayer.css';

export default class VideoPlayer extends Component {

    ref = React.createRef();
    videoRef = React.createRef();

    constructor(props) {
        super(props)
    
        this.state = {
            eventData: this.props.eventData,
            paused: false,
            currentVideoLength: 0,
            controlsHidden: false,
            isFullscreen: false
        }

        this.currentChapter = this.props.user.tracking.current.chapter;
        this.currentVideo = this.props.user.tracking.current.video;
        this.currentTimestamp = this.props.user.tracking.current.timestamp;

        this.lastSaveTime = 0;
        this.lastMouseMove = 0;

    }

    componentDidMount() {
        setTimeout(() => {
            this.ref.current.classList.add("open");
            this.render();
        }, 100);
    }

    WatchedEverything() {
        for (let i = 0; i < this.props.user.tracking.progress.length; i++) {
            const prog = this.props.user.tracking.progress[i];
            if (!prog.finished) return false;
        }
        return true;
    }

    ParseTime(time) {
        time = Math.floor(time);
        let s,m;
        m = Math.floor(time/60);
        s = time % 60;

        if (isNaN(m) || isNaN(s)) return '--:--';

        return `${m.toString().padStart(2, 0)}:${s.toString().padStart(2, '0')}`

    }

    GetChapter(chapterId) {
        if (!this.state.eventData) return;

        return this.state.eventData.chapters[chapterId];

    }

    GetVideo(chapterId, videoId) {
        const chapter = this.GetChapter(chapterId);

        if (!chapter) return;
        return chapter.videos[videoId];
    }

    GetVideoUrl(chapterId, videoId) {
        const video = this.GetVideo(chapterId, videoId);
        if (!video) return;
        return video.url.replace("/videos/", "/vids/");
    }

    GetProgressTimestamp(chapterId, videoId) {

        for (let i = 0; i < this.props.user.tracking.progress.length; i++) {
            const progress = this.props.user.tracking.progress[i];
            if (progress.chapter === chapterId && progress.video === videoId) return progress.timestamp;
        }
        return null;

    }

    Close = () => {

        this.ref.current.classList.remove("open");

        setTimeout(() => {
            this.props.onClose();
        }, 500);

    }
    

    render() {

        return (
            <div onMouseMove={e => {
                this.lastMouseMove = performance.now();
                this.setState({controlsHidden: false});
            }} ref={this.ref} className="VideoPlayer">
                <video className={this.state.controlsHidden ? ' hidden' : null} ref={this.videoRef} onEnded={e => {

                    const te = e.currentTarget;

                    //save event
                    fetch('/finishVideo', {
                        method: 'PUT',
                        mode: 'same-origin',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        redirect: 'manual',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify({
                            chapter: this.currentChapter,
                            video: this.currentVideo,
                            token: this.props.user.sessionToken
                        })
                    })
                        .then(res => res.json())
                        .then(json => {
                            console.log(json);

                            //go to next video
                            this.currentTimestamp = 0;
                            this.lastSaveTime = 0;
                            this.currentVideo++;

                            if (!this.GetVideo(this.currentChapter, this.currentVideo)) {
                                //no next video, go to next chapter
                                this.currentVideo = 0;
                                this.currentChapter++;
                            }

                            if (!this.GetChapter(this.currentChapter)) {
                                //attempted chapter doesn't exist. Guess you're done!
                                this.Close();
                                return console.log("All finished?");
                            }

                            setTimeout(() => {
                                te.src = this.GetVideoUrl(this.currentChapter, this.currentVideo);
                            }, 100);

                        })
                        .catch(err => console.error(err));

                    }} onLoadStart={e => {
                        
                        e.currentTarget.currentTime = this.GetProgressTimestamp(this.currentChapter, this.currentVideo);
                        for (let i = 0; i < this.props.user.tracking.progress.length; i++) {
                            const prog = this.props.user.tracking.progress[i];
                            if (prog.chapter === this.currentChapter && prog.video === this.currentVideo && prog.finished) e.currentTarget.currentTime = 0;
                        }
                        e.currentTarget.play();
                    }} onTimeUpdate={e => {

                        this.setState({currentVideoLength: e.currentTarget.duration});
                        this.currentTimestamp = e.currentTarget.currentTime;

                        //if mouse hasnt moved in a while lets make controls invisible
                        if (performance.now() - this.lastMouseMove > 3000) {
                            this.setState({controlsHidden: true});
                        }

                        if (performance.now() - this.lastSaveTime > 10000) {
                            //save data every ten seconds while watching
                            //console.log("SAVE DATA");
                            fetch("/updateTracking", {
                                method: 'PUT',
                                mode: 'same-origin',
                                cache: 'no-cache',
                                credentials: 'same-origin',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                redirect: 'manual',
                                referrerPolicy: 'no-referrer',
                                body: JSON.stringify({
                                    progress: {
                                        chapter: this.currentChapter,
                                        video: this.currentVideo,
                                        timestamp: e.currentTarget.currentTime
                                    },
                                    token: this.props.user.sessionToken
                                })
                            })
                                .then(res => res.json())
                                .then(json => {
                                    console.log(json);
                                })
                                .catch(e => console.error(e));
                            

                            this.lastSaveTime = performance.now();
                        }

                    }} onClick={e => {
                        if (e.currentTarget.paused) e.currentTarget.play();
                        else e.currentTarget.pause();

                        this.setState({paused: e.currentTarget.paused});

                    }} onContextMenu={e => e.preventDefault()} style={{width: '100%', maxHeight: '100vh'}} src={this.GetVideoUrl(this.currentChapter, this.currentVideo)}></video>

                    <button onClick={this.Close} className={"closeButton" + (this.state.controlsHidden ? ' hidden' : '')}><i className="fas fa-arrow-left"></i></button>

                    <div className={"controls" + (this.state.controlsHidden ? ' hidden' : '')}>
                        <div className="timeArea">
                            <div onClick={(e) => {
                                const percent = (e.clientX - e.currentTarget.offsetLeft) / e.currentTarget.clientWidth;
                                this.videoRef.current.currentTime = this.state.currentVideoLength * percent;
                            }} className="progressBar">
                                <div className="fg" style={{
                                    width: `${Math.floor(this.currentTimestamp / this.state.currentVideoLength * 10000)/100}%`
                                }}></div>
                            </div>
                            <div className="timeleft">{this.ParseTime(this.state.currentVideoLength - this.currentTimestamp)}</div>
                        </div>
                        <div className="buttons">
                            <button onClick={e => {
                                this.videoRef.current.click();
                            }} className="playButton"><i className={this.state.paused ? "fas fa-play" : "fas fa-pause"}></i></button>
                            <div>{this.GetChapter(this.currentChapter).title}</div>
                            <div> </div>
                            <button onClick={e => {
                                const te = e.currentTarget;
                                const fs = !this.state.isFullscreen;
                                this.setState({isFullscreen: fs}, () => {

                                    if (fs) this.videoRef.current.parentElement.requestFullscreen();
                                    else document.exitFullscreen();

                                })
                            }}><i className="fas fa-expand"></i></button>
                            <div className="playlistButton" style={{position:'relative'}}><i className="fas fa-layer-group"></i>
                                    <div className="playlist">
                                        <h4>Lenders Meeting 2024</h4>
                                        {this.state.eventData.chapters.map((chapter, i) => {

                                            return <button className={this.currentChapter === i ? 'active' : null} key={`playlist_${chapter.title}`} onClick={() => {
                                                this.currentChapter = i;
                                                this.currentVideo = 0;
                                                this.videoRef.current.src = this.GetVideoUrl(i, 0);
                                            }}>{chapter.title}</button>

                                        })}
                                    </div>
                                </div>
                        </div>
                    </div>

            </div>
        )
    }
}
