import React, { Component } from 'react';
import './AnalyticsReport.css';
import BarChart from './BarChart';

export default class AnalyticsReport extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            users: null,
            mainChart: null,
            extraChart: null

        }
    }

    componentDidMount() {

        fetch('/getUsers?token='+this.props.user.sessionToken)
            .then(res => {
                if (res.status === 200) {
                    res.json()
                        .then(json => {
                            this.setState({users: json.content}, this.MakeChartData);
                            console.log(json.content);
                        })
                        .catch(console.error)
                } else {
                    console.error(res);
                }
            })
            .catch(console.error)

    }

    MakeChartData = () => {

        const users = this.state.users.users;
        console.log(users);
        // return;
        const totalMainVids = users[0].tracking.progress.length;
        const totalExtraVids = users[0].extraTracking.length;

        const mainWatchlist = new Array(totalMainVids).fill(0);
        const extraWatchlist = new Array(totalExtraVids).fill(0);

        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            
            for (let j = 0; j < user.tracking.progress.length; j++) {
                const vid = user.tracking.progress[j];
                if (vid.finished) mainWatchlist[j]++;
            }

            if (!user.extraTracking) {
                console.log(`${user.email} has no extra tracking...`);
                continue;
            }

            for (let j = 0; j < user.extraTracking.length; j++) {
                const vid = user.extraTracking[j];
                if (vid.finished) extraWatchlist[j]++;
            }

        }

        const mainLabels = new Array(totalMainVids).fill(1).map((v, i) => {return `video ${i+1}`});
        const extraLabels = new Array(totalExtraVids).fill(1).map((v, i) => `Extra video ${i+1}`);

        const mainData = {
            labels: mainLabels,
            datasets: [
                {
                    label: "# Users watched",
                    data: mainWatchlist,
                    backgroundColor: '#ffcd00'
                }
            ]
        };

        const extraData = {
            labels: extraLabels,
            datasets: [
                {
                    label: "# Users watched",
                    data: extraWatchlist,
                    backgroundColor: '#ffcd00'
                }
            ]
        }

        this.setState({
            mainChart: {
                data: mainData,
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0
                            }
                        }
                    }
                }
            },
            extraChart: {
                data: extraData,
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0
                            }
                        }
                    }
                }
            }
        })

    }
    

    render() {

        let users;

        if (this.state.users) {

            users = this.state.users.users;

            users.sort((a, b) => {

                if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
                return -1;

            })

        }

        return (
            <div className="AnalyticsReport">
                <h1>Analytics</h1>
                {this.state.mainChart ? <BarChart style={{maxWidth: '600px', margin: '0 auto'}} data={this.state.mainChart.data} options={this.state.mainChart.options} /> : ''}
                {this.state.extraChart ? <BarChart style={{maxWidth: '600px', margin: '0 auto'}} data={this.state.extraChart.data} options={this.state.extraChart.options} /> : ''}
                <h2>Users</h2>
                {this.state.users ? (
                    <div className="users">
                        {users.map((user, i) => {

                            let mainVidsFinished = 0;
                            let extraVidsFinished = 0;

                            for (let j = 0; j < user.tracking.progress.length; j++) {
                                const vid = user.tracking.progress[j];
                                if (vid.finished) mainVidsFinished++;
                            }
                            for (let j = 0; j < user.extraTracking.length; j++) {
                                const vid = user.extraTracking[j];
                                if (vid.finished) extraVidsFinished++;
                            }

                            return (
                                <div key={`reportUser_${user.email}`} className="user">
                                    <p>{user.firstName} {user.lastName} - {user.email}</p>
                                    <p>Main videos: {mainVidsFinished}/{user.tracking.progress.length}</p>
                                    <p>Extra videos: {extraVidsFinished}/{user.extraTracking.length}</p>
                                    <div className="progressGrid">
                                        <div className="main">
                                            <h3>Main Videos</h3>
                                            {user.tracking.progress.map((mainVid, j) => {

                                                return (
                                                    <div key={`mainVidSquare_${user.email}_${j}`} className="vidSquare">
                                                        <div className="inner">
                                                            {mainVid.finished ? '✔' : '❌'}
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                        <div className="extra">
                                            <h3>Extra videos</h3>
                                            {user.extraTracking.map((extraVid, j) => {

                                                return (
                                                    <div key={`extraVidSquare_${user.email}_${j}`} className="vidSquare">
                                                        <div className="inner">
                                                            {extraVid.finished ? '✔' : '❌'}
                                                        </div>
                                                    </div>
                                                )

                                            })}

                                        </div>
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                ) : ''}

            </div>
        )
    }
}
