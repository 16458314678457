import React, { Component } from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import LoginPage from './LoginPage';
import Event from './Event';
import AnalyticsReport from './AnalyticsReport';

export default class App extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      user: null,
      showReport: false
    }
  }

  componentDidMount() {

    fetch('/user')
        .then(res => res.json())
        .then(json => {
          if (json.status === 200) {
            this.setState({
              user: json.content,
              token: json.content.sessionToken
            });
            // console.log(json.content)

            if (window.location.pathname === '/register') {
              window.location.href = '/';
            }

          } else {
            console.error(json.content);
          }
        })
        .catch(console.error);

  }

  GetUser = () => {
    fetch('/user?token='+this.state.user.sessionToken)
        .then(res => res.json())
        .then(json => {
          if (json.status === 200) {
            this.setState({
              user: json.content,
              token: json.content.sessionToken
            });
            // console.log(json.content)

            if (window.location.pathname === '/register') {
              window.location.href = '/';
            }

          } else {
            console.error(json.content);
          }
        })
        .catch(console.error);
  };

  Login = (user) => {
    this.setState({user: user});
    // console.log(user);
  };
  

  render() {

    // return (
    //   <Router>
    //     <Switch>
    //       <Route path="/register">
    //         <div className="App">
    //           <Header />
    //           <RegisterPage />
    //           <Footer />
    //         </div>
    //       </Route>
    //       <Route path="/">
    //       <div className="App">
    //         <Header />
    //         {!this.state.user ? <LoginPage onLogin={this.Login} /> : <Event user={this.state.user} />}
    //         <Footer />
    //       </div>
    //       </Route>
    //     </Switch>
    //   </Router>
    // )

    return (
      <div className="App">
        <Header />
        {!this.state.user ? <LoginPage onLogin={this.Login} /> : this.state.showReport ? <AnalyticsReport onClose={() => this.setState({showReport: false})} user={this.state.user} /> : <Event triggerReport={() => {this.setState({showReport: true})}} refreshUser={this.GetUser} user={this.state.user} />}
        <Footer />
      </div>
    )
  }
}
