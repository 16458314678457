import React, { Component } from 'react';
import './ExtraVideoPlayer.css';

export default class ExtraVideoPlayer extends Component {

    ref = React.createRef();
    videoRef = React.createRef();
    lastMouseMove = 0;
    lastSave = 0;

    constructor(props) {
        super(props)
    
        this.state = {
            hideControls: false
        };

        const t = this.props.user.extraTracking;

        if(t){
            for (let i = 0; i < t.length; i++) {
                const vid = t[i];
                if (vid.url === this.props.src) {
                    this.vid = vid;
                    break;
                }
            }
        }
    }
    

    componentDidMount() {
        setTimeout(() => {
            this.ref.current.classList.add('open');
        }, 100);

        this.videoRef.current.play();

    }

    TimeSinceLastMouseMove = () => {
        return performance.now() - this.lastMouseMove;
    }

    Close = () => {
        this.ref.current.classList.remove("open");
        setTimeout(() => {
            this.props.onClose();
        }, 500);
    }

    render() {
        return (
            <div onMouseMove={() => {
                this.lastMouseMove = performance.now();
                this.setState({hideControls: false});
            }} ref={this.ref} className="ExtraVideoPlayer">
                <video onEnded={e => {

                    fetch("/finishExtra", {
                        method: 'PUT',
                        mode: 'same-origin',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        redirect: 'manual',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify({
                            token: this.props.user.sessionToken,
                            url: this.props.src
                        })
                    })
                        .then(res => res.json())
                        .then(json => {
                            console.log(json);
                        })
                        .catch(e => console.error(e));
                    

                }} onTimeUpdate={e => {
                    
                    if (performance.now() - this.lastMouseMove > 3000) {
                        this.setState({hideControls: true});
                    }

                    if (performance.now() - this.lastSave > 10000) {
                        this.lastSave = performance.now();

                        fetch("/updateExtraTracking", {
                            method: 'PUT',
                            mode: 'same-origin',
                            cache: 'no-cache',
                            credentials: 'same-origin',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            redirect: 'manual',
                            referrerPolicy: 'no-referrer',
                            body: JSON.stringify({
                                token: this.props.user.sessionToken,
                                url: this.props.src,
                                timestamp: e.currentTarget.currentTime
                            })
                        })
                            .then(res => res.json())
                            .then(json => {
                                console.log(json);
                            })
                            .catch(e => console.error(e));
                        

                    }

                }} onLoadStart={e => {
                    e.currentTarget.currentTime = this.vid.timestamp;
                }} ref={this.videoRef} controls src={this.props.src}></video>

                <button onClick={this.Close} className={"closeBtn" + (this.state.hideControls ? ' hidden' : '')}><i className="fas fa-arrow-left"></i></button>

            </div>
        )
    }
}
