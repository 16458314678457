import React, { Component } from 'react';
import './UserEditPanel.css';

export default class UserEditPanel extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            origEmail: this.props.user.email,
            email: this.props.user.email,
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            rank: this.props.user.rank,
            newRank: this.props.user.rank,
            code: this.props.user.code,
            error: null,
            waiting: false
        }

        this.ref = React.createRef();

        this.codeTable = 'ABCDEFGHIJKLMNPQRSTUVWXYZ0123456789';

    }

    componentDidMount() {
        setTimeout(() => {
            this.ref.current.classList.add("open");
        }, 100);
    }

    Close = () => {
        this.ref.current.classList.remove("open");
        setTimeout(() => {
            this.props.onClose();
        }, 500);
    };

    DeleteUser = () => {

        this.setState({waiting: true, error: null});

        setTimeout(() => {
            
            fetch('/user/'+this.state.origEmail, {
                method: 'DELETE',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'manual',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({})
            })
                .then(res => res.json())
                .then(json => {
    
                    if (json.status !== 200) {
                        this.setState({
                            error: json.content,
                            waiting: false
                        })
                    } else {
                        this.Close();
                    }
    
                })
                .catch(console.error);

        }, 1000);

    };

    UpdateUser = () => {

        this.setState({waiting: true, error: null});

        setTimeout(() => {
            
            fetch('/user/'+this.state.origEmail, {
                method: 'PUT',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'manual',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    rank: this.state.newRank,
                    token: this.props.editor.sessionToken,
                    passcode: this.state.code
                })
            })
                .then(res => res.json())
                .then(json => {
    
                    if (json.status !== 200) {
                        this.setState({
                            error: json.content,
                            waiting: false
                        })
                    } else {
                        this.Close();
                    }
    
                })
                .catch(console.error);

        }, 1000);

        

    };

    RandCode = () => {
        let code = '';
        for (let i = 0; i < 6; i++) {
            code += this.codeTable[Math.floor(Math.random()*this.codeTable.length)];
        }
        this.setState({code: code});
    }
    

    render() {
        return (
            <div onClick={(e) => {if (e.currentTarget === e.target) this.Close()}} ref={this.ref} className="UserEditPanel">
                <div className="box">
                    <h2>Edit User</h2>
                    <p>{this.state.origEmail}</p>
                    {this.state.rank >= this.props.editor.rank ? (
                        <div className="error">You do not have permission to edit this user</div>
                    ) : ''}
                    {!this.state.error ? '' : (
                        <div className="error">{this.state.error}</div>
                    )}
                    <div className="grid c1fr1fr">
                        <div className="field">
                            <p className="title">First name</p>
                            <input disabled={this.state.rank >= this.props.editor.rank || this.state.waiting ? true : false} onChange={(e) => {this.setState({firstName: e.currentTarget.value})}} value={this.state.firstName} type="text" />
                        </div>
                        <div className="field">
                            <p className="title">Last name</p>
                            <input disabled={this.state.rank >= this.props.editor.rank || this.state.waiting  ? true : false} onChange={(e) => {this.setState({lastName: e.currentTarget.value})}} value={this.state.lastName} type="text" />
                        </div>
                    </div>

                    <div className="field">
                        <p className="title">Email address</p>
                        <input disabled={this.state.rank >= this.props.editor.rank || this.state.waiting  ? true : false} onChange={(e) => {this.setState({email: e.currentTarget.value})}} value={this.state.email} type="text" />
                    </div>
                    {this.state.rank >= this.props.editor.rank ? '' : (
                        <div className="field">
                            <p className="title">Passcode</p>
                            <div className="grid">
                                <button onClick={this.RandCode} title="Generate a new random code" disabled={this.state.rank >= this.props.editor.rank || this.state.waiting  ? true : false} className="reroll"><i className="fas fa-dice"></i></button>
                                <input disabled={true} onChange={(e) => {this.setState({code: e.currentTarget.value})}} value={this.state.code} type="text" />
                            </div>
                        </div>
                    )}
                    <div className="field">
                        <p className="title">Access level</p>
                        <select value={this.state.newRank} onChange={(e) => {this.setState({newRank: parseInt(e.currentTarget.value)})}} disabled={this.state.rank >= this.props.editor.rank || this.state.waiting ? true : false}>
                            {/* <option key={this.state.rank}>{this.props.ranks[this.state.rank]}</option> */}
                            {this.props.ranks.map((v, i) => {

                                if (i > this.props.editor.rank) return;

                                return <option value={i} key={v}>{v}</option>

                            })}
                        </select>
                    </div>
                    
                    <div disabled={this.state.waiting ? true : false} style={{textAlign:'right'}} className="buttons">
                        <button onClick={this.DeleteUser} disabled={this.state.waiting || this.state.rank >= this.props.editor.rank ? true : false} style={{
                            width: 'auto',
                            background: '#a52d2d',
                            float: 'left'
                        }} className="submitBtn">Delete user</button>
                        <button onClick={this.Close} style={{
                            color:'inherit',
                            margin: '0 1rem',
                            background: 'none',
                            border: 'none',
                            
                        }}>Cancel</button>
                        <button onClick={this.UpdateUser} disabled={this.state.rank >= this.props.editor.rank || this.state.waiting ? true : false} style={{width: 'auto'}} className="submitBtn">{!this.state.waiting ? 'Submit' : <i style={{fontSize: '1.5rem'}} className="fas fa-radiation spinning"></i>}</button>
                    </div>

                </div>
            </div>
        )
    }
}
