import React, { Component } from 'react';
import './Header.css';

export default class Header extends Component {
    render() {
        return (
            <div className="Header" style={{
                background: `url('/images/banner2021_bg.png')`,
                backgroundPosition: '0 30%'
            }}>
                <div className="container">
                    <img className="headerimg" src="/images/banner_2024.png" />
                </div>
            </div>
        )
    }
}
