import React, { Component } from 'react';
import './UserStats.css';
import ViewUser from './ViewUser';

export default class UserStats extends Component {

    constructor(props) {
        super(props);

        this.perPage = 25;

        this.pageCount = Math.ceil(this.props.data.users.length / this.perPage);
    
        this.state = {
            sort: null,
            sortOrder: 0,
            perPage: this.perPage,
            pageCount: this.pageCount,
            page: 1,
            searchQuery: '',
            viewUser: null
        }
    }

    componentDidMount() {
        // console.log(this.props.data); //this.props.data.users & this.props.data.sessions
    }

    SetSort = (key) => {
        if (this.state.sort !== key) {
            this.setState({sort: key, sortOrder: 0});
        } else {
            this.setState({sortOrder: this.state.sortOrder === 0 ? 1 : 0});
        }
    };
    

    render() {

        const caret = this.state.sortOrder === 0 ? <i className="fas fa-caret-down"></i> : <i className="fas fa-caret-up"></i>;

        let pageUsers = [];
        Object.assign(pageUsers, this.props.data.users);

        if (this.state.searchQuery !== '') {

            for (let i = pageUsers.length-1; i >= 0; i--) {
                const user = pageUsers[i];
                if (!user.email.includes(this.state.searchQuery.toLowerCase())) pageUsers.splice(i, 1);
            }

        } else pageUsers = this.props.data.users.slice((this.state.page - 1) * this.state.perPage, (this.state.page - 1) * this.state.perPage + this.state.perPage);

        switch (this.state.sort) {
            case 'email':
                pageUsers.sort((a,b) => {
                    if (a.email > b.email) return this.state.sortOrder === 0 ? -1 : 1;
                    else return this.state.sortOrder === 0 ? 1 : -1;
                });
                break;
            case 'rank':
                pageUsers.sort((a,b) => {
                    if (a.rank > b.rank) return this.state.sortOrder === 0 ? -1 : 1;
                    else return this.state.sortOrder === 0 ? 1 : -1;
                });
                break;
            case 'vidsWatched':
                pageUsers.sort((a,b) => {
                    let vidsWatchedA = 0;
                    let vidsWatchedB = 0;
                    for (let i = 0; i < a.tracking.progress.length; i++) {
                        const vidA = a.tracking.progress[i];
                        const vidB = b.tracking.progress[i];
                        if (vidA.finished) vidsWatchedA++;
                        if (vidB.finished) vidsWatchedB++;
                        
                    }

                    if (vidsWatchedA > vidsWatchedB) return this.state.sortOrder === 0 ? -1 : 1;
                    else return this.state.sortOrder === 0 ? 1 : -1;
                });
                break;
        
            default:
                break;
        }

        return (
            <div className="UserStats">
                <div className="field">
                    <p className="title">Email Address</p>
                    <div className="inputGroup">
                        <div style={{padding: '0.5rem'}}><i className="fas fa-search"></i></div>
                        <input value={this.state.searchQuery} onChange={(e) => this.setState({searchQuery: e.currentTarget.value})} type="text" />
                    </div>
                </div>
                <h4 className="pageTitle"><button onClick={() => this.setState({page: 1})}><i className="fas fa-angle-double-left"></i></button><button onClick={() => {
                    if (this.state.page > 1) this.setState({page: this.state.page-1});
                }}><i className="fas fa-angle-left"></i></button> Page {this.state.page} of {this.state.pageCount} <button onClick={() => {
                    if (this.state.page < this.state.pageCount) this.setState({page: this.state.page+1});
                }}><i className="fas fa-angle-right"></i></button><button onClick={() => this.setState({page: this.state.pageCount})}><i className="fas fa-angle-double-right"></i></button></h4>
                <div className="headers">
                    <button onClick={() => this.SetSort("email")} className="header">User {this.state.sort === 'email' ? caret : ''}</button>
                    <button onClick={() => this.SetSort("rank")} className="header">Access Level {this.state.sort === 'rank' ? caret : ''}</button>
                    <button onClick={() => this.SetSort("vidsWatched")} className="header">Videos Completed {this.state.sort === 'vidsWatched' ? caret : ''}</button>
                </div>
                <div className="userTable">
                    {pageUsers.map(user => {

                        let vidsWatched = 0;
                        for (let i = 0; i < user.tracking.progress.length; i++) {
                            const video = user.tracking.progress[i];
                            if (video.finished) vidsWatched++;
                        }
                        // for (let i = 0; i < user.tracking.ppts.length; i++) {
                        //     const ppt = user.tracking.ppts[i];
                        //     if (ppt.viewed) pptsViewed++;
                        // }


                        return (
                            <div onClick={e => this.setState({viewUser: user})} key={user._id} className="user">
                                <div>
                                    <p style={{
                                        textTransform: 'uppercase',
                                        fontSize: '0.7rem',
                                        fontFamily: 'monospace',
                                        color: 'darkgrey'
                                    }}>{user.email}</p>
                                    <p>{user.firstName} {user.lastName}</p>
                                </div>
                                <div>{this.props.ranks[user.rank].split(" - ")[0]}</div>
                                <div>{vidsWatched}/{user.tracking.progress.length}</div>
                            </div>
                        );

                    })}
                </div>

                {this.state.viewUser ? <ViewUser onClose={() => this.setState({viewUser: null})} user={this.state.viewUser} ranks={this.props.ranks} /> : ''}

            </div>
        )
    }
}
