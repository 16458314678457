import React, { Component } from 'react';
import './Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <div className="container">
                    <img className="footerLogo" src="/images/ergon-logo.png" />
                    <div className="links">
                        <div>
                            <p>P.O. Box 1639</p>
                            <p>Jackson, MS</p>
                            <p>39215-1639 USA</p>
                            <p>+1 601-933-3000</p>

                            <p style={{marginTop: '1rem'}}>Ergon, Inc.</p>
                            <p>Copyright &copy; 2024. All Rights Reserved.</p>
                        </div>
                        <div>
                            <ul>
                                <li><a href="https://ergon.com/industry/ESS">Energy &amp; Specialty Solutions</a></li>
                                <li><a href="https://ergon.com/industry/PCR">Pavement &amp; Coating Resources</a></li>
                                <li><a href="https://ergon.com/industry/ISL">Integrated Services &amp; Logistics</a></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li><a href="https://ergon.com/about">About</a></li>
                                <li><a href="https://ergon.com#">SDS</a></li>
                                <li><a href="https://ergon.com/crudeoil">Crude Prices</a></li>
                                <li><a href="https://ergon.com/ergon/corporate_services">Corporate</a></li>
                                <li><a href="https://ergon.com/updates">Updates</a></li>
                                <li><a href="https://ergon.com/ergon/contact">Contact</a></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li><a href="https://ergon.com/privacy-policy">Privacy Policy</a></li>
                                <li><a href="https://ergon.com/files/EQS-PO-003-Ergon-Environmental-Policy.pdf">Environmental Policy</a></li>
                                <li><a href="https://ergon.com/files/EQS-PO-001-ErgonQualityPolicy.pdf">Ergon Quality Policy</a></li>
                                <li><a href="https://ergon.com/files/PO-TERMS-CON.pdf">Purchase Order Terms &amp; Conditions</a></li>
                                <li><a href="https://ergon.com/files/Ergon-Safety-Commitment.pdf">Safety Commitment</a></li>
                                <li><a href="https://ergon.com/california-transparency-in-supply-chains-act">California Transparency in<br />Supply Chains Act</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
