import React, { Component } from 'react';
import './LoginPage.css';

export default class LoginPage extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            email: '',
            passcode: '',
            waiting: false,
            error: null,
            success: null,
            slide: 0,
            codeTimer: 0
        };

        this.slideRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef()
        ];

        this.slideContainerRef = React.createRef();
        this.boxRef = React.createRef();
        this.sendCodeBtnRef = React.createRef();
        this.loginBtnRef = React.createRef();
        this.attendeeBtnRef = React.createRef();
    }

    componentDidMount() {
        this.ChangeSlide(0);
    }

    SetCodeTimer(i) {
        this.setState({codeTimer: i});

        if (i > 0) setTimeout(() => {
            this.SetCodeTimer(i-1);
        }, 998);

    }

    ChangeSlide(i) {

        this.setState({
            slide: i,
            waiting: false,
            error: null,
            success: null
        });

        this.slideContainerRef.current.style.left = `${-(i * this.slideRefs[0].current.getBoundingClientRect().width)}px`;

    }

    SetWaiting() {
        this.setState({
            waiting: true,
            success: null,
            error: null
        });
    }

    ResolveWaiting(resolveObject) {
        this.setState({
            waiting: false,
            ...resolveObject
        });
    }

    Fetch(path, method, body) {

        return new Promise((resolve, reject) => {

            fetch(path, {
                method: method,
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': "application/json"
                },
                redirect: 'manual',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(body)
            })
                .then(res => res.json())
                .then(json => {
                    resolve(json);
                })
                .catch(reject);

        });

    }

    SendCode = () => {
        this.SetWaiting();

        setTimeout(() => {
            
            this.Fetch('/sendCode', 'POST', {email: this.state.email})
                .then(res => {
                    if (res.status === 200) {
                        // this.ResolveWaiting({success: "Your passcode has been sent!"});
                        // this.ResolveWaiting({success: "Normally I would email you your code.. But since this is a dev environment, it is " + res.content});
                        this.ChangeSlide(1);
                        setTimeout(() => {
                            this.ResolveWaiting({success: "Your passcode has been emailed to you. Paste the code below to continue."});
                        }, 100);
                        this.SetCodeTimer(30);
                    } else if (res.status === 404) {
                        this.ResolveWaiting({error: "This email has not been invited to participate in the event. Please contact an administrator if you believe this is an error."});
                    } else {
                        this.ResolveWaiting({error: res.content})
                    }
                })
                .catch((err) => console.error(err));

        }, 1000);
    };

    AttendeeLogin = () => {
        
        this.SetWaiting();

        setTimeout(() => {
            
            this.Fetch('/attendeeLogin', "POST", {
                email: this.state.email
            })
                .then(res => {
                    if (res.status === 200) {
                        this.props.onLogin(res.content);
                    } else if (res.status === 403) {
                        this.ResolveWaiting({error: res.content});
                    } else {
                        this.ResolveWaiting({error: 'This email has not been invited to participate in the event. Please contact an administrator if you believe this is an error.'});
                    }
                })
                .catch(console.error);

        }, 1000);

    };

    Login = () => {

        this.SetWaiting();

        setTimeout(() => {
            
            this.Fetch('/login', 'POST', {
                email: this.state.email,
                code: this.state.passcode
            })
                .then(res => {
                    if (res.status === 200) {
                        this.props.onLogin(res.content);
                    } else {
                        this.ResolveWaiting({error: "Invalid login"});
                    }
                })
                .catch(err => console.error(err));

        }, 1000);

    };

    FieldsFilled() {
        const test = /^\s*$/;
        if (test.test(this.state.email) || test.test(this.state.passcode)) return false;
        return true;
    }

    IsEmpty(str) {
        if (/^\s*$/.test(str)) return true;
        return false;
    }
    

    render() {

        const loadSpinner = <i className="fas fa-radiation spinning" style={{fontSize: '1.2rem'}}></i>;

        return (
            <div className="LoginPage">
                <div ref={this.boxRef} className="box">
                    <h1 style={{padding: '1rem'}} align="center">Welcome</h1>

                    {this.state.error ? <div className="message bg-red">{this.state.error}</div> : ''}
                    {this.state.success ? <div className="message bg-green">{this.state.success}</div> : ''}

                    <div ref={this.slideContainerRef} style={{
                        height: (this.slideRefs[this.state.slide].current ? `${this.slideRefs[this.state.slide].current.getBoundingClientRect().height}px` : '0')
                    }} className="slides">

                        <div ref={this.slideRefs[0]} className="slide">
                            {/* <h2>Sign In</h2> */}
                            <p>Enter your preregistered email address to log in.</p>
                            <div className="inputGroup">
                                <div className="title">Email Address</div>
                                <input onKeyDown={e => {
                                    if (e.code === 'Enter') this.attendeeBtnRef.current.click();
                                }} disabled={this.state.waiting ? true : false} tabIndex={this.state.slide === 0 ? null : "-1"} value={this.state.email} onChange={(e) => this.setState({email: e.currentTarget.value})} type="text" />
                            </div>
                        </div>

                        <div ref={this.slideRefs[1]} className="slide">
                            <h2>Sign in</h2>
                            <div className="inputGroup">
                                <div className="title">Email Address</div>
                                <input disabled={this.state.waiting ? true : false} tabIndex={this.state.slide === 1 ? null : "-1"} value={this.state.email} onChange={(e) => this.setState({email: e.currentTarget.value})} type="text" />
                            </div>
                            <div className="inputGroup">
                                <div className="title">Passcode</div>
                                <input onKeyDown={e => {
                                    if (e.code === 'Enter') this.loginBtnRef.current.click();
                                }} disabled={this.state.waiting ? true : false} tabIndex={this.state.slide === 1 ? null : "-1"} value={this.state.passcode} onChange={(e) => this.setState({passcode: e.currentTarget.value})} type="password" />
                                <button tabIndex={this.state.slide === 1 ? null : "-1"} disabled={this.state.waiting ? true : false} onClick={() => this.ChangeSlide(2)} className="nobg" style={{color: '#0099ff'}}>Forgot your code?</button>
                            </div>
                        </div>

                        <div ref={this.slideRefs[2]} className="slide">
                            <h2>Send Passcode</h2>
                            <p>Enter your email address and we will send you your passcode</p>
                            <div className="inputGroup">
                                <div className="title">Email Address</div>
                                <input onKeyDown={e => {
                                    if (e.code === 'Enter') this.sendCodeBtnRef.current.click();
                                }} disabled={this.state.waiting ? true : false} tabIndex={this.state.slide === 2 ? null : "-1"} value={this.state.email} onChange={(e) => this.setState({email: e.currentTarget.value})} type="text" />
                            </div>
                        </div>

                    </div>
                    <div className="buttons">
                        {this.state.slide === 0 ? (
                            <div>
                                <button onClick={() => this.ChangeSlide(1)} disabled={this.state.waiting ? true : false} className="nobg" style={{float: 'left', color: '#0099ff'}}>Site Administrator Login</button>
                                <button ref={this.attendeeBtnRef} onClick={this.AttendeeLogin} disabled={this.state.waiting || this.IsEmpty(this.state.email) ? true : false} className="bg-green">{this.state.waiting ? loadSpinner : 'Submit'}</button>
                            </div>
                        ) : this.state.slide === 1 ? (
                            <div>
                                <button onClick={() => this.ChangeSlide(0)} disabled={this.state.waiting ? true : false} className="nobg" style={{float: 'left'}}><i className="fas fa-arrow-left"></i> Go back</button>
                                <button ref={this.loginBtnRef} onClick={this.Login} disabled={this.state.waiting || !this.FieldsFilled() ? true : false} className="bg-green">{this.state.waiting ? loadSpinner : 'Submit'}</button>
                            </div>
                        ) : this.state.slide === 2 ? (
                            <div>
                                <button onClick={() => this.ChangeSlide(1)} disabled={this.state.waiting ? true : false} className="nobg" style={{float: 'left'}}><i className="fas fa-arrow-left"></i> Go back</button>
                                <button ref={this.sendCodeBtnRef} onClick={this.SendCode} disabled={this.state.waiting || this.IsEmpty(this.state.email) || this.state.codeTimer > 0 ? true : false} className="bg-green">{this.state.waiting ? loadSpinner : this.state.codeTimer > 0 ? this.state.codeTimer : 'Submit'}</button>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        )
    }
}
