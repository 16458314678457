import React, { Component } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

Chart.defaults.color = '#777777';


export default class BarChart extends Component {
    ref = React.createRef();

    constructor(props) {
        super(props);
    
        this.opts = {
            type: 'bar',
            data: this.props.data,
            options: this.props.options
        };
    }
    

    componentDidMount() {
        const chart = new Chart(this.ref.current, this.opts);
    }

    render() {
        return (
            <div style={this.props.style}>
                <canvas ref={this.ref}></canvas>
            </div>
        )
    }
}
