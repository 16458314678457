import React, { Component } from 'react';
import './ViewUser.css';

export default class ViewUser extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            open: false,
            eventData: null
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({open: true});

            fetch("/eventData")
                .then(res => res.json())
                .then(json => {
                    if (json.status === 200) {
                        this.setState({eventData: json.content});
                    }
                })
                .catch(e => console.error(e));
            

        }, 100);

    }
    
    Close = () => {

        this.setState({open: false});

        setTimeout(() => {
            this.props.onClose();
        }, 500);
    };

    render() {

        let vidsWatched = 0, totalVids = this.props.user.tracking.progress.length;

        // console.log(this.props.user);

        for (let i = 0; i < this.props.user.tracking.progress.length; i++) {
            const video = this.props.user.tracking.progress[i];
            if (video.finished) vidsWatched++;
        }

        if (!this.state.eventData) return "";

        return (
            <div onClick={e => { if (e.target === e.currentTarget) this.Close() }} className={`ViewUser${this.state.open ? ' open' : ''}`}>
                <div className="box">
                    <h2>{this.props.user.firstName} {this.props.user.lastName}</h2>
                    <p style={{fontStyle: 'italic'}}>{this.props.ranks[this.props.user.rank].split(" - ")[0]}</p>
                    <p>{this.props.user.email}</p>
                    <hr />
                    <h3 style={{marginTop: '1rem'}}>Videos Watched ({vidsWatched}/{totalVids})</h3>
                    <div className="vidGrid">
                        {this.props.user.tracking.progress.map((vid, i) => {

                            return (
                                <div key={`vidCell${i}`} className={`vidCell${vid.finished ? ' watched' : vid.timestamp > 0 ? ' watching' : ''}`}>
                                    <h4>{this.state.eventData.chapters[vid.chapter].videos[vid.video].title}</h4>
                                    <p>{vid.finished ? 'Watched' : vid.timestamp > 0 ? (vid.chapter === this.props.user.tracking.current.chapter && vid.video === this.props.user.tracking.current.video ? 'Watching Now' : 'Started') : 'Not watched'}</p>
                                </div>
                            )

                        })}
                    </div>
                    
                    <div className="buttons">
                        <button onClick={this.Close} style={{float:'right'}} className="viewClose">Close</button>
                    </div>

                </div>
            </div>
        )
    }
}
