import React, { Component } from 'react';
import './AdminPanel.css';
import AdminUserEdit from './AdminUserEdit';
import BarChart from './BarChart';
import UserStats from './UserStats';

export default class AdminPanel extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            category: 'Dashboard',
            data: null,
            mainChartData: null,
            mainChartOptions: null,

            //add user
            addUserWaiting: false,
            addUserError: null,
            firstName: '',
            lastName: '',
            email: '',
            rank: 0

        }

        this.categories = [
            {
                name: 'Dashboard',
                rankRequired: 1,
                category: 'Analytics'
            },
            {
                name: 'User Stats',
                rankRequired: 1,
                category: 'Analytics'
            },
            {
                name: 'User Management',
                rankRequired: 2,
                category: 'Management'
            }
        ];

        this.ranks = ['Event Attendee - Event access only', 'Analyst - Access to analytics', 'Event Administrator - Access to user management', 'Operator - Site Developers'];

        this.ref = React.createRef();

    }

    componentDidMount() {
        setTimeout(() => {
            this.ref.current.classList.add("open");
        }, 100);

        this.GetUsers(this.MakeCharts);

    }

    GetUsers = (callback=undefined) => {
        fetch('/getUsers?token='+this.props.user.sessionToken)
            .then(res => res.json())
            .then(json => {

                if (json.status === 200) {
                    // console.log(json.content);

                    this.setState({data: json.content}, () => {
                        if (callback !== undefined) callback();
                    });

                } else {
                    console.error(json.content);
                }

            })
            .catch(console.error);
    };

    MakeCharts = () => {

        //charts

        const users = this.state.data.users;
        const totalVids = users[0].tracking.progress.length;
        const watchlist = new Array(totalVids).fill(0);

        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            for (let j = 0; j < user.tracking.progress.length; j++) {
                const vid = user.tracking.progress[j];
                if (vid.finished) watchlist[j]++;
            }
        }

        const mainLabels = new Array(totalVids).fill(1).map((v, i) => {return 'vid ' + (i+1)});

        const maindata = {
            labels: mainLabels,
            datasets: [
                {
                    label: "# Users Watched",
                    data: watchlist,
                    backgroundColor: '#ffcd00'
                }
            ]
        };

        this.setState({
            mainChartData: maindata,
            mainChartOptions: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0
                        }
                    }
                }
            }
        });

        // const mainChart = new Chart(document.querySelector("#mainChart"), {
        //     type: 'bar',
        //     data: maindata,
        //     options: {
        //         scales: {
        //             y: {
        //                 beginAtZero: true
        //             }
        //         }
        //     }
        // });

    }

    ChangeCategory = (name) => {
        this.setState({category: name});
    }

    Close = () => {
        this.ref.current.classList.remove("open");
        setTimeout(() => {
            this.props.onClose();
        }, 500);
    };

    AddNewUser = () => {

        this.setState({
            addUserWaiting: true,
            addUserError: null
        });

        setTimeout(() => {
            
            fetch('/user', {
                method: 'POST',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'manual',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    rank: this.state.rank,
                    token: this.props.user.sessionToken
                })
            })
                .then(res => res.json())
                .then(json => {

                    if (json.status !== 200) return this.setState({
                        addUserWaiting: false,
                        addUserError: json.content,
                        firstName: '',
                        lastName: '',
                        email: '',
                        rank: 0
                    });

                    this.setState({
                        addUserWaiting: false,
                        firstName: '',
                        lastName: '',
                        email: '',
                        rank: 0
                    });

                    this.GetUsers();

                })
                .catch(err => {
                    this.setState({
                        addUserError: err,
                        addUserWaiting: false,
                        firstName: '',
                        lastName: '',
                        email: '',
                        rank: 0
                    })
                });

        }, 1000);

    };
    

    render() {

        //statistics
        const showStats = this.state.data ? true : false;
        const stats = {
            usersDone: null
        };

        if (showStats) {

            const users = this.state.data.users;
            let totalVids = 0;

            let usersDone = 0;
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                let allDone = true;
                for (let j = 0; j < user.tracking.progress.length; j++) {
                    if (totalVids === 0) totalVids = user.tracking.progress.length;
                    const vid = user.tracking.progress[j];
                    if (!vid.finished) allDone = false;
                }
                if (allDone) usersDone++;
                
            }
            stats.usersDone = usersDone;

        }



        let categoryPage;

        switch (this.state.category) {
            case 'Dashboard':
                categoryPage = (
                    <div className="analytics">
                        <div className="quickStats">
                            <div className="stat">
                                <h4>Registered users</h4>
                                <p>{!this.state.data ? '--' : this.state.data.users.length}</p>
                            </div>
                            <div className="stat">
                                <h4>Active sessions</h4>
                                <p>{!this.state.data ? '--' : this.state.data.sessions.length}</p>
                            </div>
                            <div className="stat">
                                <h4>Users 100% complete</h4>
                                <p>{showStats ? `${stats.usersDone} (${Math.floor(stats.usersDone/this.state.data.users.length*10000)/100}%)` : '--'}</p>
                            </div>
                        </div>
                        <h2>Breakdown</h2>
                        {this.state.mainChartData && this.state.mainChartOptions ? <BarChart data={this.state.mainChartData} options={this.state.mainChartOptions} /> : ''}

                        <button onClick={this.props.triggerReport}>Printable Analytics Report</button>

                    </div>
                );
                break;
            case 'User Stats':
                categoryPage = (
                    <UserStats user={this.props.user} ranks={this.ranks} data={this.state.data} />
                );
                break;
            case 'User Management':

                    const addUserFieldsFilled = this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '' ? true : false;

                    categoryPage = (
                        <div className="usrMgmt">
                            <div className="addUser">
                                <h4>Add a user</h4>
                                {!this.state.addUserError ? '' : <div className="error">{this.state.addUserError}</div>}
                                <div className="grid c1fr1fr">
                                    <div className="field">
                                        <p className="title">First Name</p>
                                        <input disabled={!this.state.addUserWaiting ? false : true} onChange={(e) => this.setState({firstName: e.currentTarget.value})} value={this.state.firstName} autoComplete="nope" type="text" />
                                    </div>
                                    <div className="field">
                                        <p className="title">Last Name</p>
                                        <input disabled={!this.state.addUserWaiting ? false : true} onChange={(e) => this.setState({lastName: e.currentTarget.value})} value={this.state.lastName} autoComplete="nope2" type="text" />
                                    </div>
                                </div>
                                <div className="field">
                                    <p className="title">Email Address</p>
                                    <input disabled={!this.state.addUserWaiting ? false : true} onChange={(e) => this.setState({email: e.currentTarget.value})} value={this.state.email} autoComplete="nope3" type="text" />
                                </div>
                                <div className="field">
                                    <p className="title">Access Level</p>
                                    <select value={this.state.rank} onChange={(e) => this.setState({rank: parseInt(e.currentTarget.value)})}>
                                        {this.ranks.slice(0, this.props.user.rank+1).map((v,i) => {
                                            return <option key={v} value={i}>{v}</option>
                                        })}
                                    </select>
                                </div>
                                <button onClick={this.AddNewUser} disabled={addUserFieldsFilled && !this.state.addUserWaiting ? false : true} className="submitBtn">{!this.state.addUserWaiting ? 'Add user' : <i className="fas fa-radiation spinning noMouse" style={{fontSize:'1.5rem'}}></i>}</button>

                                <AdminUserEdit user={this.props.user} ranks={this.ranks} data={this.state.data} refresh={this.GetUsers} />

                            </div>
                        </div>
                    );
                    break;
        
            default:
                break;
        }

        const cats = {};

        for (let i = 0; i < this.categories.length; i++) {
            const category = this.categories[i];
            if (!cats[category.category]) cats[category.category] = [];
            cats[category.category].push(category);
        }

        const cathtml = Object.keys(cats).map(c => {
            if (cats[c].length == 0) return;
            return (
                <div key={'cat_'+c}>
                    <p className="title">{c}</p>
                    <ul>
                        {cats[c].map(v => {
                            if (this.props.user.rank >= v.rankRequired) return <li onClick={() => this.ChangeCategory(v.name)} className={this.state.category === v.name ? 'active' : null} key={v.name}>{v.name}</li>
                        })}
                    </ul>
                </div>
            );

        });

        return (
            <div ref={this.ref} className="AdminPanel">
                <div className="categories">
                    <div className="box">
                        <h2 style={{marginBottom: '1rem'}}>Admin</h2>
                        {cathtml}
                        {/* <ul>
                            {this.categories.map((v, i) => {
                                if (this.props.user.rank >= v.rankRequired) return <li onClick={() => this.ChangeCategory(v.name)} className={this.state.category === v.name ? 'active' : null} key={v.name}>{v.name}</li>
                            })}
                        </ul> */}
                    </div>
                </div>
                <div className="body">
                    <div className="inner">
                        <button onClick={this.Close} className="closeBtn">
                            <i className="far fa-times-circle noMouse"></i>
                            <i className="fas fa-times-circle noMouse"></i>
                        </button>
                        <h3 style={{marginBottom: '1rem'}}>{this.state.category}</h3>
                        {categoryPage}
                    </div>
                </div>
            </div>
        )
    }
}
