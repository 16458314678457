import React, { Component } from 'react';
import './Event.css';
import AdminPanel from './AdminPanel';
import VideoPlayer from './VideoPlayer';
import ExtraVideoPlayer from './ExtraVideoPlayer';

function FormatTime(time) {
    let s,m,h,d;

    s = Math.floor(time/1000);
    m = Math.floor(s/60);
    s %= 60;
    h = Math.floor(m/60);
    m %= 60;
    d = Math.floor(h/24);
    h %= 24;

    const out = [];
    const li = [d,h,m,s];
    const table = ['day', 'hour', 'minute', 'second'];

    

    for (let i = 0; i < li.length; i++) {
        const frag = li[i];
        const end = frag === 1 ? '' : 's';

        if (frag > 0) out.push(`${frag} ${table[i]}${end}`);
    }

    if (out.length === 0) return '';

    return out.join(", ");

}

export default class Event extends Component {

    // eventUnlockTime = Date.now() + 10*1000;
    // extraVidsUnlockTime = Date.now() + 20*1000;
    eventUnlockTime = new Date('Wed, 15 Nov 2023 20:00:00 GMT').getTime();
    extraVidsUnlockTime = new Date('Wed, 15 Nov 2023 21:30:00 GMT').getTime();

    // eventUnlockTime = new Date('Wed, 16 Nov 2022 20:00:00 GMT').getTime();
    // extraVidsUnlockTime = new Date('Wed, 16 Nov 2022 21:30:00 GMT').getTime();

    currentRealTime = Date.now();

    constructor(props) {
        super(props)
    
        this.state = {
            adminPanelOpen: false,
            eventData: null,
            vidOpen: false,
            extraVid: null,
            eventStarted: false,
            extrasUnlocked: false,
            eventTimerText: "Event begins on 11/16/2023, 2:00PM CST",
            compatibilityMode: false
        }


    }

    componentDidMount() {

        this.GetEventData();

        this.TestEventTime();
        this.TestExtrasTime();
        

    }

    TestEventTime = () => {

        if (Date.now() >= this.eventUnlockTime) {
            this.setState({eventStarted: true});
        } else {
            this.setState({eventTimerText: 'Event begins in ' + FormatTime(this.eventUnlockTime - Date.now())});
            setTimeout(this.TestEventTime, 1000);
        }

        

    }

    TestExtrasTime = () => {
        if (Date.now() >= this.extraVidsUnlockTime) {
            this.setState({extrasUnlocked: true});
        } else {
            setTimeout(this.TestExtrasTime, 1000);
        }
    }

    GetEventData() {
        fetch('/eventData')
            .then(res => res.json())
            .then(json => {
                if (json.status === 200) {
                    this.setState({eventData: json.content});
                    console.log(json.content);
                } else {
                    console.error(`ERR: ${json.content}`);
                }
            })
            .catch(e => console.error(e));
    }

    MakeVideoList = () => {

        const urls = [];
        const names = [];

        for (let i = 0; i < this.state.eventData.chapters.length; i++) {
            const chapter = this.state.eventData.chapters[i];
            urls.push(chapter.videos[0].url);
            names.push(chapter.title);
        }

        return urls.map((url, i) => {

            url = url.replace("/videos/", "/vids/")

            return (
                <div key={`video_`+url} className="mvidrow">
                    <video controls style={{
                        maxWidth: '100%'
                    }} src={url}></video>
                </div>
            )

        });

    }

    render() {

        const user = this.props.user;

        const vidlist = this.state.eventData ? this.MakeVideoList() : null;

        return (
            <div className="Event">
                <div className="header">
                    <div className="container">
                        <ul>
                            <li><i className="fas fa-user"></i> {this.props.user.firstName} {this.props.user.lastName}</li>
                            {this.props.user.rank >= 1 ? <li><button onClick={() => this.setState({adminPanelOpen: true})}>Admin</button></li> : ''}
                            <li><a href="/logout">Log out</a></li>
                        </ul>
                    </div>
                </div>

                <div className="container" style={{padding: '1rem',
                background: '#ffffee',
                margin: '1rem auto',
                marginBottom: '4rem',
                borderRadius: '1rem',
                boxShadow: '1px 1px 16px #00000020',
                border: 'solid 2px #00000010'
            }}>

                    <h2 style={{fontSize: '2rem'}} className='yellow'>Wednesday, November 20, 2024</h2>
                    <h3>2:00 CST at The Sheraton Flowood: The Refuge Hotel &amp; Conference Center</h3>

                    <h3 style={{fontSize: '1.5rem', margin: '1rem 0'}}>REGISTER BY WEDNESDAY, 10/16</h3>
                    <p><a target='_blank' style={{textDecoration: 'none', color: 'inherit'}} href="https://corexmsqrf6bzcptclld.qualtrics.com/jfe/form/SV_dncHllxCoMAXE9M" rel='noreferrer'><button className='btn' style={{
                        background: '#44aa12',
                        color: 'white',
                        padding: '1rem',
                        width: '100%',
                        maxWidth: '500px',
                        margin: '1rem auto',
                        display: 'block',
                        border: 'solid 2px #00000070',
                        borderRadius: '0.5rem',
                        cursor: 'pointer'
                    }}>CLICK TO REGISTER</button></a></p>

                    <p style={{marginBottom: '1rem'}}>Please complete the registration form linked above no later than Wednesday, October 16.</p>
                    <hr /><br />
                    <p style={{marginBottom: '1rem'}}><em>A block of rooms has been reserved at The Sheraton Flowood: The Refuge Hotel & Conference Center.</em> </p>

                    <p style={{textAlign: 'center'}}><a href="https://urldefense.com/v3/__https:/www.marriott.com/event-reservations/reservation-link.mi?id=1727212056567&key=GRP&guestreslink2=true__;!!FOfmI8qiWcWBHqypJtzENF0!1Un4bU5_ehS5mdV2Sk4oBmviYZqCjLSS0SMGOSdU2_p9nYVEHnttV4zC9mFEwXFGrbV1n__awB8iZ5AhECDTG8sjphNOH9Ida4Vx%24" target='_blank' rel='noreferrer'>Book your group rate for Ergon Lenders Meeting &raquo; </a></p>
                    
                    <ul style={{padding: 'revert', margin: '1rem'}}>
                        <li><b>Last Day to Book: </b>Friday, November 01, 2024, or once the block is full</li>
                        <li>$179.00 USD per night</li>
                        <li>Located at 2200 Refuge Boulevard in Flowood - <a href="tel:601-936-4550" style={{textDecoration: 'none', color: 'inherit'}}>601-936-4550</a></li>
                    </ul>

                    <p><b>Mini Code:</b> ERG</p>
                    <p><b>Start Date:</b> Wednesday, November 20, 2024</p>
                    <p><b>End Date:</b> Thursday, November 21, 2024</p>
                    <br />
                    <hr />
                    <ul style={{padding: 'revert', margin: '1rem'}}>
                        <li>The Lenders Meeting, followed by a cocktail hour and dinner, will be held at The Sheraton Flowood: The Refuge Hotel & Conference Center beginning at 2:00 p.m. on November 20</li>
                        <li>Guests will be able to register for outings the morning of November 21:</li>
                            <ul style={{padding: 'revert'}}>
				<li>Kearney Park
                            		<ul style={{padding: 'revert'}}>
                                		<li>Breakfast only</li>
                                		<li>Sporting clays (target shooting/other)</li>
                                		<li>Quail hunting</li>
					</ul>
				</li>
				<li>The Refuge at The Sheraton
                            		<ul style={{padding: 'revert'}}>
						<li> Team golf </li>
                                		<li>Spa (Lenders only) - <em>Spa is fully booked and unable to accept more reservations at this time. </em></li>
					</ul>
				</li>
                            </ul>
                    </ul>

                    {/* images */}

                    <ul style={{padding: 'revert'}}><li style={{margin: "1rem 0"}}><a target="_blank" href="/images/lenders_meeting_map.png">Map of Event Locations</a></li></ul>


                    {/* <div className="mapGrid" style={{background: 'white'}}>
                        <img src="/images/map1.png" alt="" />
                        <img src="/images/map2.png" alt="" />
                    </div> */}

                    <p style={{marginTop: '1rem'}}>If you have any questions, please feel free to contact <a href="mailto:ken.hodges@ergon.com">ken.hodges@ergon.com</a>.</p>

                    <p style={{marginTop: '1rem'}}>We will see you on November 20!</p>

                    <p style={{marginTop: '1rem'}}><em>For any new guests who might be interested in learning more about Ergon, please see the information below.</em></p>

                </div>

                <div className="container" style={{padding: '1rem'}}>
                    {/*
                    <h2 style={{fontSize: '2rem'}} className="yellow">Welcome to our virtual 2021 Lenders Meeting!</h2>
                    <p style={{fontSize: '1.2rem'}}>We appreciate your support and willingness to join us in this format for the second year. You’ll hear from men and women across Ergon’s organization as they cover FY 2021 results, what we are doing today, and our outlook for tomorrow.</p>
                    */}
                    <p className="title" style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Confidential information.</p>
                    <p>Please keep in mind that this information, including the financial package we are sending to you, shall be held in strict confidence.</p>
                    <br />
                    <p>Thanks again for your time. We look forward to working with you as we continue to move this great company forward by meeting needs, supporting families and serving customers.</p>
                        
                    {/* <p style={{
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        textAlign: 'right'
                    }}>- The Ergon Family</p> */}
{/*
                    <div className="info">
                        <div className="infoCol">
                            <p className="title">Need to take a break?</p>
                            <p>You can pause the video or close the browser window and return without logging in again — provided you use the same computer and browser upon return. If needed, your passcode can be retrieved at any time via the website login page.</p>
                            <p className="title">Questions?</p>
                            <p>While we are not set up to take live questions during the presentation, we encourage you to send any questions to a member of the Finance Department listed below, and we will address them directly.</p>
                        </div>
                        <div className="infoCol">
                            <p>&nbsp;</p>
                            <p>The site will be available for viewing through Tuesday, November 30, in case someone in your organization wasn't able to attend at the regular time or if you would like to watch the video again.</p>
                            <p className="title">Confidential information.</p>
                            <p>Please keep in mind that this presentation, including the video and the financial package we are sending to you, are to be held in strict confidence.</p>
                            <p>Thanks again for your time. We look forward to working with you as we continue to move this great company forward by meeting needs, supporting families and serving customers.</p>
                        </div>
                    </div>
                    <div className="notice">
                        <p>Technical issues? Text 601-209-2183.<br />Technical support will be available during the presentation.</p>
                    </div>
*/}
                </div>

                <div className="contacts">
                    <div className="container">
                        <div className="contact">
                            <p className="title">Alan Wall</p>
                            <p>alan.wall@ergon.com</p>
                            <p>601.933.3268</p>
                        </div>
                        <div className="contact">
                            <p className="title">Lee Adams</p>
                            <p>lee.adams@ergon.com</p>
                            <p>601.933.3195</p>
                        </div>
                        <div className="contact">
                            <p className="title">Ken Hodges</p>
                            <p>ken.hodges@ergon.com</p>
                            <p>601.933.3343</p>
                        </div>
                        {/* <div className="contact">
                            <p className="title">John Wallace</p>
                            <p>john.wallace@ergon.com</p>
                            <p>601.933.3270</p>
                        </div> */}
                    </div>
                </div>

                <div className="catchup container">
                    <div className="catchupCol">
                        <h3 className="yellow">For anyone who has not participated in previous Ergon Lenders Meetings:</h3>
                        <p>The following videos provide an overview of our history and a glimpse into the values of our founder, <span style={{whiteSpace: 'nowrap'}}>Leslie B. Lampton Sr.</span>, as shared with the third generation of his family.</p>
                        <br />
                    </div>
                    <div className="catchupCol grid c1fr1fr">
                        <div className="catchupVideo">
                            <video src="/vids/catchup1.mp4" controls></video>
                            <div onClick={(e) => {
                                e.currentTarget.parentElement.querySelector("video").play();
                                e.currentTarget.remove();
                            }} className="vidCard" style={{backgroundImage: "url(/images/catchup1card.jpg)"}}><i className="fas fa-play"></i></div>
                        </div>
                        <div className="catchupVideo">
                            <video src="/vids/catchup2.mp4" controls></video>
                            <div onClick={(e) => {
                                e.currentTarget.parentElement.querySelector("video").play();
                                e.currentTarget.remove();
                            }} className="vidCard" style={{backgroundImage: "url(/images/catchup2card.jpg)"}}><i className="fas fa-play"></i></div>
                        </div>
                    </div>
                </div>
                {/*
                {this.state.eventStarted ? (
                    <div className="videoContainer">
                        {this.state.compatibilityMode ? (
                            <div className="container">
                                <h2>Lenders Meeting 2021</h2>
                                <div className="vidgrid">
                                    {/* <div className="mvidrow">
                                        <i className="fas fa-play"></i>
                                    </div> */} {/*
                                    {vidlist}
                                </div>
                            </div>
                        ) : (
                            <div className="container">
                                <div onClick={() => this.setState({vidOpen: true})} className="mainVidCard" style={{background: `url('/images/videoBg.jpg') no-repeat`, backgroundSize: 'cover'}}>
                                    <div className="content">
                                        <span style={{fontSize: '3em', color: '#ffdd00'}}><i className="fas fa-play"></i></span>
                                    </div>
                                </div>

                                <button onClick={() => this.setState({compatibilityMode: true})} style={{
                                    margin: '1em',
                                    padding: '1em',
                                    fontSize: 'inherit'
                                }}>Having trouble viewing videos?</button>

                            </div>
                        )}
                    </div>
                ) : (
                    <div className="videoContainer">
                        <div className="container">
                            <h2>{this.state.eventTimerText}</h2>
                        </div>
                    </div>
                )}
                */}
                
                <div className="extraVids" style={{background: '#3c4447'}}>
                    {/*
                    <div className="container">
                        <h2 style={{margin: '1em'}}>Sustainable Product Development at Ergon</h2>

                        <div className="topRow">
                            <div>
                            <p style={{margin: '1em'}}>Kris Patrick, Chief Operating Officer, talks with leaders from Process Oils, Asphalt & Emulsions, Specialty Chemicals and Corporate Business Development about a variety of innovation efforts around sustainable products.</p>
                            <p style={{margin: '1em'}}>We think Ergon is uniquely suited to succeed in this area based on our history and on the DNA of our company.</p>
                            </div>
                            <div className="card" style={{margin: '1em auto', width: '80%'}}>
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg Intro Feat. Kris Patrick-5.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg Intro Feat. Kris Patrick-5.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="extraGrid">
                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Process Oils Feat. Nick White-3.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Process Oils Feat. Nick White-3.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Asphalt Emulsions Feat. Dr. Gaylon Baumgardner-1.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Asphalt Emulsions Feat. Dr. Gaylon Baumgardner-1.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Specialty Chemicals Feat. Ken Cooley-4.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Specialty Chemicals Feat. Ken Cooley-4.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Esg - Business Development Feat. Craig Busbea-2.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 1;
                                    }} src="/vids/Esg - Business Development Feat. Craig Busbea-2.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                            </div>
                        </div>

                    </div>
*/}
                </div>
                <div className="extraVids">
                    <div className="container">
                        <h2>Extra Videos</h2>
                        <div className="extraGrid">
                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Respectful Relationships Final-3.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 14;
                                    }} src="/vids/Respectful Relationships Final-3.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                                <p>Respectful Relationships</p>
                            </div>
                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Empowered Service Final-1.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 14;
                                    }} src="/vids/Empowered Service Final-1.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                    </div>
                                <p>Empowered Service</p>
                            </div>
                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Selfless Leadership Final 1-4.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 14;
                                    }} src="/vids/Selfless Leadership Final 1-4.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                                <p>Selfless Leadership</p>
                            </div>
                            <div className="card">
                                <div className="vid">
                                    <video onClick={e => {
                                        this.setState({extraVid: "/vids/Purposeful Growth Final-2.mp4"})
                                    }} onLoadStart={e => {
                                        e.currentTarget.currentTime = 14;
                                    }} src="/vids/Purposeful Growth Final-2.mp4"></video>
                                    <div className="playbtn"><i className="fas fa-play"></i></div>
                                </div>
                                <p>Purposeful Growth</p>
                            </div>
                        </div>
                    </div>
                </div>
                

                {this.state.eventData !== null && this.state.vidOpen ? <VideoPlayer onClose={() => {
                    this.setState({vidOpen: false});
                    this.props.refreshUser();
                }} user={this.props.user} eventData={this.state.eventData} /> : ''}

                {this.state.extraVid !== null ? <ExtraVideoPlayer user={this.props.user} onClose={() => {
                    this.setState({extraVid: null});
                }} src={this.state.extraVid} /> : ''}

                {this.props.user.rank >= 1 && this.state.adminPanelOpen ? <AdminPanel triggerReport={this.props.triggerReport} eventData={this.state.eventData} user={this.props.user} onClose={() => this.setState({adminPanelOpen: false})} /> : ''}

            </div>
        )
    }
}
